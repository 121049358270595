.container {
    position: relative;
}

.main{
    position: relative;
    height:544px;
    max-height: calc(100vh - 250px);
    text-align:center;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background:#ffffff;
    border: 0.75px solid #727272;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.pdf{
    min-height:544px ;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.controls{
    position: sticky;
    bottom: 0;
    padding: 10px;
    color: #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    background: #B1B1B1;
    button{
        color: #ffffff;
        border: none;
        background: transparent;
        margin: 0 10px;
    }
}

 .canvasContainer {
    background:#000000;
}
.pagination{
    font-size: 16px;
    padding: 0 10px;
}
.fullScreen{
    padding: 0 10px;
    cursor: pointer;
}
.zoom{
    padding: 0 10px;
    font-size: 24px;
}