.share-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .share-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 512px;
    min-height: 450px;
    margin: 0 10px;
  }
  
  .share-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h3{
     font-size: 22px; 
    }
  }
  
  .close-btn {
    border: none;
    background: transparent;
  }
  
  .share-modal-body {
    display: flex;
    flex-direction: column;
  }
  
  .search-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .search-users{
    flex: 1;
    position: relative;
  }
  .search-input {
    width: 95%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .role-select {
    /* padding: 8px; */
    border: none;
    /* border-radius: 4px; */
    font-size: 12px;
    width: 60px;
  }
  
  .user-list {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    position: absolute;
    width: 100%;
    background: #fff;
  }
  
  .search-result {
    cursor: pointer;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
  
  .selected-users {
    /* border: 1px solid #ccc; */
    /* border-radius: 4px; */
    padding: 10px 0;
    /* height: 100%;
    max-height: 370px;
    overflow-y: auto; */
    h4{
        font-size: 18px;
        /* font-weight: 700; */
        padding: 10px 0;
        margin-bottom: 10px;
        /* border-bottom: 1px solid lightgray; */
    }
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        height: 100%;
        max-height: 270px;
        overflow-y: auto;
    }
    li{
        padding: 10px 0;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    li:not(:last-child){
        border-bottom: 1px solid lightgray;
    }
    &.search{
        max-height: 320px;
    }
    p{
      font-size: 14px;
    }
    p:first-letter{
      text-transform: uppercase;
    }
  }
  
  .selected-users h4 {
    margin-top: 0;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .generate-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  .generate-btn:hover {
    background-color: #0056b3;
  }
  
  /* Custom styles for the checkbox */
.form-check-input {
    /* Increase the size of the checkbox */
    /* width: 30px;
    height: 30px; */
  }
  
  /* Custom styles for the checkbox label */
  .form-check-label {
    /* Increase font size and adjust padding */
    font-size: 14px;
    padding-left: 10px; /* Adjust padding as needed */
  }
  