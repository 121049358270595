.modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}
div.react-select__control{
    border: none;
    height: 22px;
    min-height: auto;
}
div.react-select__indicators{
    display: none;
}
div.react-select__value-container{
    top: -3px;
    padding: 0 8px;
}
